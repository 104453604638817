import React, { forwardRef } from 'react';
import { VStack, Box, Text, Button, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogCloseButton, AlertDialogBody } from '@chakra-ui/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { JSONTree } from 'react-json-tree';
import axios from 'axios';
import { Buffer } from 'buffer';

const BoxUser = styled(Box)`
    border-radius: 8px;
    padding: 8px;
    margin: 4px;
    background-color: #EDF2F7;
    border: 1px solid #A0AEC0;
    align-self: flex-start;
    max-width: 80%;
`;

const BoxAgent = styled(Box)`
    border-radius: 8px;
    padding: 8px;
    margin: 4px;
    background-color: #bee3f8;
    border: 1px solid #63b3ed;
    align-self: flex-end;
    max-width: 80%;
`;

const CustomText = styled(Text)`
    font-size: 14px;
    padding: 0 12px;
`

const ToolUl = styled.ul`
    font-size: 14px;
    padding: 0 12px;
    & ul {
        padsding-left: 12px;
    }
`;

const scrollStyle = css`
  /* Estilizar el scrollbar */
  &::-webkit-scrollbar {
    width: 8px; /* Ancho del scrollbar */
    margin-left: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888; /* Color del thumb (barra de desplazamiento) */
    border-radius: 4px; /* Redondear los bordes del thumb */
    border: 2px solid transparent; /* Espacio alrededor del thumb */
    background-clip: padding-box; /* Muestra el espacio alrededor del thumb */
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color de fondo del track (pista del scrollbar) */
    border-radius: 4px; /* Redondear los bordes del track */
    margin: 3px;
  }
`;

const options = {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false, // Para formato de 24 horas
    timeZone: 'UTC' // Si quieres forzar UTC, de lo contrario remueve esto
};

const ChatWindow = forwardRef(({ chat }, ref) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const cancelRef = React.useRef();
    const [dialogBody, setDialogBody] = React.useState({});
    const [verArchivoLoading, setVerArchivoLoading] = React.useState(false);


    const onClose = () => {
        setIsOpen(false).then(() => {
            setDialogBody({});
        });
    }
    if (!chat) {
        return (
            <div className="flex-1 flex flex-col">
                <div className="flex-shrink-0 p-4 bg-gray-800 text-white border-b border-gray-300">
                    <h2 className="text-xl font-bold">Selecciona un chat</h2>
                </div>
                <div className="flex-1 p-4 bg-white"></div>
            </div>
        );
    }

    const openDialog = (content) => {
        setDialogBody({ type: "code", content: content });
        setIsOpen(true);
    };

    const GetFile = async (fileId) => {
        setVerArchivoLoading(true);
        try {
            const file = await axios.get('/files/' + fileId);
            switch (file.data.type) {
                case 0:
                    const buffer = Buffer.from(file.data.base64.data);
                    const base64String = buffer.toString('base64');
                    setDialogBody({ type: "image", content: base64String });
                    return;
            }
        } catch (error) {
            setDialogBody({ type: "text", content: "Hubo un error al obtener el archivo." });
            return;
        } finally {
            setIsOpen(true);
            setVerArchivoLoading(false);
        }
    };

    const renderTextType = (text, index, createdAt) => (
        <>
            <CustomText key={index}>
                {text.text.split('\n').map((linea, idx) => (
                    <p key={idx}>
                        {linea}
                    </p>
                ))}
            </CustomText>
            <CustomText fontSize="xs" color="gray.500" mt={2} textAlign="right">
                {new Intl.DateTimeFormat('es-ES', { options }).format(new Date(createdAt))}
            </CustomText>
        </>
    );

    const renderFileType = (content, index, formattedDate) => (
        <>
            <Button colorScheme="blue" size="sm" mt={2} onClick={() => GetFile(content.fileId)} disabled={verArchivoLoading ? "disabled" : "" }>
                {verArchivoLoading ? "Cargando..." : "Ver archivo"}</Button>
            <Text fontSize="xs" color="gray.500" mt={2} textAlign="right">
                {formattedDate}
            </Text>
        </>
    );

    const renderToolUse = (tool) => (
        <ToolUl>
            <li><strong>Nombre:</strong> {tool.name}</li>
            <li><strong>Datos:</strong></li>
            <ul>
                {tool.input && Object.keys(tool.input).map((key, index) => {
                    const value = tool.input[key];
                    if (typeof value === 'object' && value !== null) {
                        return (
                            <li key={index}>
                                <strong>{key}:</strong>
                                {/* Renderizar las propiedades del objeto de manera recursiva */}
                                <ul>
                                    {Object.keys(value).map((subKey, subIndex) => (
                                        <li key={subIndex}>
                                            <strong>{subKey}:</strong> {value[subKey] && value[subKey].toString()}
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        );
                    } else {
                        // Renderizar valores que no son objetos directamente
                        return (
                            <li key={index}>
                                <strong>{key}:</strong> {value && value.toString()}
                            </li>
                        );
                    }
                })}
            </ul>
        </ToolUl>
    );

    const renderToolResult = (tool) => (

        <Button colorScheme="blue" size="sm" mt={2} onClick={() => openDialog(tool.content)}>Ver respuesta</Button>
    );

    const resolveMessage = (message) => {
        return (
            message.content.map((content, index) => {
                switch (content.type) {
                    case "text":
                        return renderTextType(content, index, message.createdAt);
                    case "file":
                        return renderFileType(content, index, message.formattedDate);
                    case "tool_use":
                        return renderToolUse(content, index);
                    case "tool_result":
                        return renderToolResult(content, index);
                    default:
                        return null; // or some fallback UI
                }
            })
        )
    };

    return (
        <>
            <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                isOpen={isOpen}
                isCentered
                onClose={onClose}
            >
                <AlertDialogOverlay />

                <AlertDialogContent maxH="700px" maxW="900px">
                    <AlertDialogHeader>Ver contenido</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody style={{ overflow: "scroll" }}>
                        {dialogBody && dialogBody.type === "code" ?
                            <JSONTree data={JSON.parse(dialogBody.content)} />
                        : dialogBody && dialogBody.type === "image" ?
                            <img src={`data:image/jpeg;base64,${dialogBody.content}`} alt="Archivo" />
                        : dialogBody && dialogBody.type === "text" ?
                            <Text>{dialogBody.content}</Text>
                        : null}
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            Cerrar
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
            <VStack
                ref={ref}
                flex={1}
                bg="white"
                p={4}
                spacing={4}
                overflow="auto"
                borderRadius="8px"
                boxShadow="xl"
                ml={4}
                style={{ border: '1px solid #e3e3e3', padding: '4px' }}
                width="100%"
            >
                <VStack style={{ overflowY: 'scroll', width: '100%' }} css={scrollStyle}>
                    {chat.conversations.map((conversation) => (conversation.messages.map((message, index) => (
                        message.from === "Anthropic" || message.from === "API_Segurarse" ?
                            <BoxAgent key={index}>
                                {resolveMessage(message)}
                            </BoxAgent>
                            :
                            <BoxUser key={index}>
                                {resolveMessage(message)}
                            </BoxUser>
                    ))))}
                </VStack>
            </VStack>
        </>
    );
});

export default ChatWindow;
