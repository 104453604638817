import React, { useEffect, useState } from 'react';
import ChatApp from './components/ChatApp';
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Header from './components/Header';
import AzureLogin from './components/AzureLogin';

function App() {
    const { instance, accounts } = useMsal();
    const [activeAccount, setActiveAccount] = useState(null);
    const [profileImage, setProfileImage] = useState('');

    useEffect(async () => {
        await instance.initialize(); // Add this line
        if (accounts.length > 0) {
            if (!activeAccount) {
                setActiveAccount(accounts[0]);
            }
            return;
        }
    }, [instance, accounts]);

    const handleLogin = async () => {
        try {
            await instance.loginPopup({
                scopes: ["api://59573f4e-5b26-42fc-8ffa-7bb4eef3cdd0/read", "User.Read"],
            });
            console.log("Usuario autenticado");
        } catch (error) {
            console.error("Error en la autenticación:", error);
        }
    };

    useEffect(() => {
        const fetchProfileImage = async () => {
            if (activeAccount) {
                try {
                    // Obtén el token de acceso para Microsoft Graph
                    const response = await instance.acquireTokenSilent({
                        // scopes: ["api://59573f4e-5b26-42fc-8ffa-7bb4eef3cdd0/read","User.Read"],
                        scopes:["api://59573f4e-5b26-42fc-8ffa-7bb4eef3cdd0/read", "User.Read"],
                        account: activeAccount,
                    });

                    console.log("response.accessToken > ", response.accessToken);
                    const graphResponse = await fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
                        headers: {
                            Authorization: `Bearer ${response.accessToken}`
                        }
                    });

                    if (graphResponse.ok) {
                        const blob = await graphResponse.blob();

                        // Convertir blob a base64
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            setProfileImage(reader.result);  // Esto establecerá la imagen en formato base64
                        };
                        reader.readAsDataURL(blob);
                    } else {
                        console.error('Error al obtener la imagen de perfil:', graphResponse.status, graphResponse.statusText);
                    }
                } catch (error) {
                    console.error('Error al adquirir el token de acceso o al obtener la imagen', error);
                }
            }
        };

        fetchProfileImage();
    }, [activeAccount]);
    
    return (
        <>
            <AuthenticatedTemplate>
                {profileImage && <Header image={profileImage} />}
                <ChatApp />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <AzureLogin handleLogin={handleLogin} />
            </UnauthenticatedTemplate>
        </>
    );
}

export default App;