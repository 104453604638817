import React, { useEffect, useRef, useState } from 'react';
import ChatList from './ChatList';
import ChatWindow from './ChatWindow';
import io from 'socket.io-client';
import {
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Flex,
    Icon
} from '@chakra-ui/react';
import { FaComments, FaRobot } from 'react-icons/fa';
import { MdCampaign } from "react-icons/md";
import Agents from './Agents';
import ChatWidget from './ChatWidget';
import Procesito from './Procesito';

const ENV = window.location.origin;
const socket = io(ENV);

function ChatApp() {
    const [chats, setChats] = useState([]);
    const [selectedChat, setSelectedChat] = useState(null);
    const chatWindowRef = useRef(null);

    useEffect(() => {
        // Emitir la solicitud de obtener todos los chats con sus mensajes
        socket.emit('get-chats');
        debugger;
        socket.on('chats', (receivedChats) => {
            setChats(receivedChats);
            if (receivedChats.length > 0 && !selectedChat) {
                setSelectedChat(receivedChats[0]); // Seleccionar el primer chat por defecto
                scrollToBottom();
            }
        })

        // Escuchar actualizaciones de chats
        socket.on('chatChange', (updatedChat) => {
            console.log("updatedChat > ", updatedChat);
            setChats((prevChats) => {
                const chatIndex = prevChats.findIndex((chat) => chat._id === updatedChat._id);
                if (chatIndex !== -1) {
                    const newChats = [...prevChats];
                    newChats[chatIndex] = updatedChat;
                    return newChats;
                } else {
                    return [...prevChats, updatedChat];
                }
            });

            // Actualizar el chat seleccionado si es el mismo que se actualizó
            if (selectedChat && selectedChat._id === updatedChat._id) {
                setSelectedChat(updatedChat);
                scrollToBottom();
            }
        });

        return () => {
            socket.off('chats');
            socket.off('chatChange');
        };
    }, [selectedChat]);

    const scrollToBottom = () => {
        if (chatWindowRef.current) {
            chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
        }
    };

    return (
        <>
            <Tabs mt={4}>
                <TabList ml={4}>
                    <Tab>
                        <Icon as={FaComments} mr={2} />
                        Chats
                    </Tab>
                    <Tab>
                        <Icon as={FaRobot} mr={2} />
                        Agentes
                    </Tab>
                    <Tab>
                        <Icon as={MdCampaign} mr={2} />
                        Procesito
                    </Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <Flex height="80vh">
                            {chats.length > 0 && (
                                <ChatList chats={chats} onSelectChat={setSelectedChat} />
                            )}
                            {selectedChat && (
                                <ChatWindow ref={chatWindowRef} chat={selectedChat} />
                            )}
                        </Flex>
                    </TabPanel>

                    <TabPanel>
                        <Agents />
                    </TabPanel>

                    <TabPanel>
                        <Procesito />
                    </TabPanel>
                </TabPanels>
            </Tabs>
            <>
                <ChatWidget />
            </>
        </>
    );
}

export default ChatApp;