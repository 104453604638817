import React from 'react';
import { VStack, Box, Text, HStack, Avatar, Badge, InputGroup, InputLeftElement, Input, Icon } from '@chakra-ui/react';
import { FaPhoneAlt } from "react-icons/fa";
import { css } from '@emotion/react';

const scrollStyle = css`
  /* Estilizar el scrollbar */
  &::-webkit-scrollbar {
    width: 8px; /* Ancho del scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888; /* Color del thumb (barra de desplazamiento) */
    border-radius: 4px; /* Redondear los bordes del thumb */
    border: 2px solid transparent; /* Espacio alrededor del thumb */
    background-clip: padding-box; /* Muestra el espacio alrededor del thumb */
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color de fondo del track (pista del scrollbar) */
    border-radius: 4px; /* Redondear los bordes del track */
    margin: 3px;
  }
`;

function ChatList({ chats, onSelectChat }) {
    return (
        <VStack width="20%" borderRadius="8px" boxShadow="xl" bg="white" style={{border: '1px solid #e3e3e3', paddingRight: '4px', paddingBottom: '4px'}}>
            <InputGroup width={"90%"} margin={"10px"}>
                <InputLeftElement pointerEvents='none'>
                    <Icon as={FaPhoneAlt} mr={2} color={"grey.200"} />
                </InputLeftElement>
                <Input type='tel' placeholder='Buscar' />
            </InputGroup>
            <VStack css={scrollStyle} style={{ overflowY: 'scroll' }} width="100%" pt={4} height="100%">
                {chats.map((chat) => (
                    <Box
                        key={chat.chatId}
                        width="100%"
                        cursor="pointer"
                        _hover={{ bg: "gray.200" }}
                        onClick={() => onSelectChat(chat)}
                        borderBottom="1px solid #e2e8f0"
                        padding={5}
                    >
                        <HStack spacing={4}>
                            <Avatar name="Juan Perez" src="" />
                            <VStack align="start" spacing={0}>
                                <Text fontWeight="bold">{chat.owner}</Text>
                                {chat.mensajes && chat.mensajes.lenght > 0 &&
                                    <Text fontSize="sm" color="gray.600">{chat.mensajes[chat.mensajes.lenght - 1].content[0].text}</Text>
                                }
                                <Badge colorScheme="blue" mt={1}>{chat.channel}</Badge>
                            </VStack>
                        </HStack>
                    </Box>
                ))}
            </VStack>
        </VStack>
    );
}

export default ChatList;