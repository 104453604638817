import React from 'react';
import styled from '@emotion/styled';
import UserProfile from './UserProfile';
import seguLogo from '../images/segulogo.png';

const HeaderTop = styled.div`
    display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 16px;
`;

const Img = styled.img`
    height: 50px;
`;

function Header(image) {
    return (
        <HeaderTop>
            <Img src={seguLogo} alt='Logo de SeguNext' height="80px" />
            <UserProfile image={image.image} />
        </HeaderTop>
    );
}

export default Header;