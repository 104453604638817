import React, { useEffect, useState } from 'react';
import { DrawerOverlay, IconButton, Collapse, CheckboxGroup, Box, Checkbox, FormLabel, Textarea, Switch, Stack, DrawerBody, DrawerFooter, Button, DrawerContent, Drawer, SimpleGrid, Card, CardHeader, Heading, CardBody, Text, CardFooter, DrawerCloseButton, DrawerHeader, Input, useToast, Badge, Spinner } from '@chakra-ui/react';
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import axios from 'axios';

function Agents() {

    const [agents, setAgents] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const onClose = () => setIsOpen(false);
    const [agent, setAgent] = useState(null);
    const toast = useToast();
    const [loading, setLoading] = useState(true);

    const [formData, setFormData] = useState();
    const [expandedAgent, setExpandedAgent] = useState(null);


    const createAgent = () => {
        setFormData({
            tasks: '',
            active: false,
            prompt: '',
            identifier: '',
            name: '',
            mode: 'create',
            transfers: [],
            summary: ''
        });
        setIsOpen(true);
    }

    useEffect(() => {
        if (agent) {
            setFormData({
                tasks: agent.tasks || '',
                active: agent.active || false,
                prompt: agent.prompt || '',
                identifier: agent.identifier || '',
                name: agent.name || '',
                id: agent._id,
                mode: 'update',
                transfers: agent.transfers || [],
                summary: agent.summary || ''
            });
            setIsOpen(true);
        }
    }, [agent]);

    const handleChange = (e) => {
        const { id, value, type, checked } = e.target;
        if (id === 'summary' && value.length > 200) {
            return;
        }
        setFormData((prevData) => ({
            ...prevData,
            [id]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleTransferChange = (value) => {
        setFormData((prevData) => ({
            ...prevData,
            transfers: value,
        }));
    };

    const bringAgents = async () => {
        setLoading(true);
        axios.get('/virtual-agents')
            .then((response) => {
                setAgents(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    };

    useEffect(async () => {
        await bringAgents();
    }, []);

    const handleSubmit = async () => {
        try {
            switch (formData.mode) {
                case 'create':
                    await axios.post('/virtual-agents/', formData);
                    onClose();
                    toast({
                        title: 'Agente creado',
                        description: "Agente creado con éxito",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                    break;
                case 'update':
                    await axios.put('/virtual-agents/' + formData.id, formData);
                    onClose();
                    toast({
                        title: 'Agente actualizado',
                        description: "Actualizamos los datos del agente " + formData.identifier,
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                    break;
            }
        } catch (error) {
            toast({
                title: 'Error',
                description: "Hubo un error al actualizar al agente " + formData.identifier,
                status: 'warning',
                duration: 9000,
                isClosable: true,
            })
        } finally {
            await bringAgents();
        }
    };

    const toggleExpand = (agentId) => {
        setExpandedAgent(expandedAgent === agentId ? null : agentId);
    };

    return (
        <SimpleGrid spacing={4} templateColumns='repeat(auto-fill, minmax(200px, 1fr))'>
            {formData &&
                <Drawer
                    isOpen={isOpen}
                    placement='right'
                    onClose={onClose}
                    size="xl"
                >
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader borderBottomWidth='1px'>
                            Ver agente
                        </DrawerHeader>

                        <DrawerBody>
                            <Stack spacing='24px'>
                                <Box>
                                    <FormLabel htmlFor='name'>Nombre</FormLabel>
                                    <Input
                                        id='name'
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </Box>

                                <Box>
                                    <FormLabel htmlFor='identifier'>Identificador</FormLabel>
                                    <Input
                                        id='identifier'
                                        value={formData.identifier}
                                        onChange={handleChange}
                                        required
                                    />
                                </Box>

                                <Box>
                                    <FormLabel htmlFor='summary'>Resumen de lo que hace este agente</FormLabel>
                                    <Textarea
                                        id='summary'
                                        value={formData.summary}
                                        onChange={handleChange}
                                        maxLength={200}  // Limitar caracteres en el HTML
                                        required
                                    />
                                    <Text fontSize="sm">{formData.summary.length} / 200 caracteres</Text>
                                </Box>

                                <Box>
                                    <FormLabel htmlFor='promt'>Prompt</FormLabel>
                                    <Textarea id='prompt' value={formData.prompt} onChange={handleChange} required />
                                </Box>

                                <Box>
                                    <FormLabel htmlFor='tasks'>Tareas</FormLabel>
                                    <Textarea id='tasks' value={formData.tasks} onChange={handleChange} required />
                                </Box>

                                {/* Nueva sección para seleccionar agentes de transferencia */}
                                <Box>
                                    <FormLabel>Puede transferir a</FormLabel>
                                    <CheckboxGroup
                                        value={formData.transfers}
                                        onChange={handleTransferChange}
                                    >
                                        <Stack spacing={2}>
                                            {agents.map((agent) => (
                                                agent.active && agent._id !== formData.id &&
                                                <Box key={agent._id}>
                                                    <Checkbox value={agent._id}>
                                                        {agent.name} <strong>({agent.identifier})</strong>
                                                    </Checkbox>
                                                    <IconButton
                                                        icon={expandedAgent === agent._id ? <FaChevronUp /> : <FaChevronDown />}
                                                        onClick={() => toggleExpand(agent._id)}
                                                        variant="ghost"
                                                        size="sm"
                                                        marginLeft={2}
                                                    />
                                                    <Collapse in={expandedAgent === agent._id}>
                                                        <Text margin={"0 0 0 25px"} fontStyle={"italic"}>{agent.summary}</Text>
                                                    </Collapse>
                                                </Box>
                                            ))}
                                        </Stack>
                                    </CheckboxGroup>
                                </Box>

                                <Box>
                                    <FormLabel htmlFor='active' mb='0'>
                                        Activo
                                    </FormLabel>
                                    <Switch id='active' isChecked={formData.active ?? false} onChange={handleChange} />
                                </Box>
                            </Stack>
                        </DrawerBody>

                        <DrawerFooter borderTopWidth='1px'>
                            <Button variant='outline' mr={3} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme='blue' onClick={handleSubmit}>Guardar</Button>
                        </DrawerFooter>
                    </DrawerContent>
                </Drawer>
            }
            {loading &&
                <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='blue.500'
                    size='xl'
                    alignSelf='center'
                />
            }
            {!loading && agents.map((agent) => (
                <Card key={agent._id}>
                    <CardHeader>
                        <Heading size='md'>🤖 {agent.name}
                            <Badge ml='1' colorScheme={agent.active ? 'green' : ''}>
                                {agent.active ? 'Activo' : 'Inactivo'}
                            </Badge>
                        </Heading>
                    </CardHeader>
                    <CardBody>
                        <Text>Identificador: <br /><b>{agent.identifier}</b></Text>
                    </CardBody>
                    <CardFooter>
                        <Button onClick={() => setAgent(agent)}>Modificar</Button>
                    </CardFooter>
                </Card>
            ))}
            <Card>
                <CardBody>
                    <Button onClick={() => createAgent()}>Crear</Button>
                </CardBody>
            </Card>
        </SimpleGrid>
    );
}

export default Agents;