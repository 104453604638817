// UserProfileBox.js
import React from 'react';
import { Avatar, AvatarBadge, Stack, VStack } from '@chakra-ui/react';
import { useMsal } from "@azure/msal-react";
import styled from '@emotion/styled';
import { IoLogOut } from "react-icons/io5";

const SpanName = styled.span`
    font-weight: bold;
    font-size: 14px;
`;

const SpanMail = styled.span`
    font-size: 13px;
`;

function UserProfile(image) {
    const { instance, accounts } = useMsal();
    const activeAccount = accounts[0];

    const handleLogout = () => {
        instance.logoutPopup().catch(e => {
            console.error(e);
        });
    };

    if (!activeAccount) return null;

    return (
        <Stack direction='row' spacing={5} justifyContent='space-between' alignItems="center">
            <Avatar name={activeAccount.name} src={image.image}>
                <AvatarBadge boxSize='1.25em' bg='green.500' />
            </Avatar>
            <VStack alignItems='flex-start' gap={0}>
                <SpanName>{activeAccount.name}</SpanName>
                <SpanMail>{activeAccount.username}</SpanMail>
            </VStack>
            <IoLogOut color='#474747' fontSize={40} onClick={handleLogout} />
        </Stack>
    );
}

export default UserProfile;