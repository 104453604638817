// frontend/src/components/Procesito.js
import React, { useEffect, useState } from 'react';
import {
    DrawerOverlay, IconButton, Box, FormLabel, Stack, DrawerBody,
    DrawerFooter, Button, DrawerContent, Drawer, SimpleGrid, Card,
    CardHeader, Heading, CardBody, Text, CardFooter, DrawerCloseButton,
    DrawerHeader, Input, useToast, Spinner, Textarea, useDisclosure,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody,
    ModalCloseButton, ModalFooter, FormControl
} from '@chakra-ui/react';
import { FaPlay } from "react-icons/fa";
import axios from 'axios';
import { JSONTree } from 'react-json-tree';

function Procesito() {
    const [processes, setProcesses] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const onClose = () => setIsOpen(false);
    const [process, setProcess] = useState(null);
    const toast = useToast();
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState();


    // Test modal states
    const { isOpen: isTestOpen, onOpen: onTestOpen, onClose: onTestClose } = useDisclosure();
    const [selectedFile, setSelectedFile] = useState(null);
    const [testBody, setTestBody] = useState('');
    const [testResult, setTestResult] = useState('');
    const [testLoading, setTestLoading] = useState(false);
    const [selectedProcess, setSelectedProcess] = useState(null);

    const createProcess = () => {
        setFormData({
            name: '',
            body: '',
            prompt: '',
            jsonOutput: '',
            mode: 'create'
        });
        setIsOpen(true);
    }

    const fetchProcesses = async () => {
        setLoading(true);
        try {
            const response = await axios.get('/process');
            setProcesses(response.data);
        } catch (error) {
            console.error(error);
            toast({
                title: 'Error',
                description: "Error al cargar los procesos",
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (process) {
            setFormData({
                name: process.name || '',
                body: process.body || '',
                prompt: process.prompt || '',
                jsonOutput: process.jsonOutput || '',
                id: process._id,
                mode: 'update'
            });
            setIsOpen(true);
        }
    }, [process]);

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [id]: value
        }));
    };

    const handleSubmit = async () => {
        try {
            switch (formData.mode) {
                case 'create':
                    await axios.post('/process', formData);
                    onClose();
                    toast({
                        title: 'Proceso creado',
                        description: "Proceso creado con éxito",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    });
                    break;
                case 'update':
                    await axios.patch('/process/' + formData.id, formData);
                    onClose();
                    toast({
                        title: 'Proceso actualizado',
                        description: "Proceso actualizado con éxito",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    });
                    break;
            }
        } catch (error) {
            toast({
                title: 'Error',
                description: "Hubo un error en la operación",
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        } finally {
            await fetchProcesses();
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`/process/${id}`);
            toast({
                title: 'Proceso eliminado',
                description: "Proceso eliminado con éxito",
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
            await fetchProcesses();
        } catch (error) {
            toast({
                title: 'Error',
                description: "Error al eliminar el proceso",
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleTest = async () => {
        if (!selectedFile || !testBody) {
            toast({
                title: 'Error',
                description: "Debe seleccionar un archivo y completar el body",
                status: 'warning',
                duration: 9000,
                isClosable: true,
            });
            return;
        }

        setTestLoading(true);
        try {
            const reader = new FileReader();
            reader.onload = async () => {
                const base64 = reader.result.split(',')[1];
                const testData = {
                    name: selectedProcess.name,
                    body: JSON.parse(testBody),
                    base64: base64,
                    mimeType: selectedFile.type
                };

                const response = await axios.post('/process/use', testData);
                setTestResult(JSON.stringify(response.data, null, 2));
            };
            reader.readAsDataURL(selectedFile);
        } catch (error) {
            setTestResult(JSON.stringify(error.response?.data || error.message, null, 2));
        } finally {
            setTestLoading(false);
        }
    };

    useEffect(() => {
        fetchProcesses();
    }, []);

    const openTestModal = (process) => {
        setSelectedProcess(process);
        setTestBody(process.body);
        onTestOpen();
    };

    return (
        <SimpleGrid spacing={4} templateColumns='repeat(auto-fill, minmax(300px, 1fr))'>
            {formData &&
                <Drawer isOpen={isOpen} placement='right' onClose={onClose} size="xl">
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader borderBottomWidth='1px'>
                            {formData.mode === 'create' ? 'Crear Proceso' : 'Modificar Proceso'}
                        </DrawerHeader>

                        <DrawerBody>
                            <Stack spacing='24px'>
                                <Box>
                                    <FormLabel htmlFor='name'>Nombre del Proceso</FormLabel>
                                    <Input
                                        id='name'
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </Box>

                                <Box>
                                    <FormLabel htmlFor='body'>Body Schema (JSON)</FormLabel>
                                    <Textarea
                                        id='body'
                                        value={formData.body}
                                        onChange={handleChange}
                                        height="150px"
                                        required
                                    />
                                </Box>

                                <Box>
                                    <FormLabel htmlFor='prompt'>Prompt</FormLabel>
                                    <Textarea
                                        id='prompt'
                                        value={formData.prompt}
                                        onChange={handleChange}
                                        height="150px"
                                        required
                                    />
                                </Box>

                                <Box>
                                    <FormLabel htmlFor='jsonOutput'>JSON Output Schema</FormLabel>
                                    <Textarea
                                        id='jsonOutput'
                                        value={formData.jsonOutput}
                                        onChange={handleChange}
                                        height="150px"
                                        required
                                    />
                                </Box>
                            </Stack>
                        </DrawerBody>

                        <DrawerFooter borderTopWidth='1px'>
                            <Button variant='outline' mr={3} onClick={onClose}>
                                Cancelar
                            </Button>
                            <Button colorScheme='blue' onClick={handleSubmit}>Guardar</Button>
                        </DrawerFooter>
                    </DrawerContent>
                </Drawer>
            }

            <Modal isOpen={isTestOpen} onClose={onTestClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Probar Proceso: {selectedProcess?.name}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack spacing={4}>
                            <FormControl>
                                <FormLabel>Archivo</FormLabel>
                                <Input
                                    type="file"
                                    onChange={(e) => setSelectedFile(e.target.files[0])}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Body (JSON)</FormLabel>
                                <Textarea
                                    value={testBody}
                                    onChange={(e) => setTestBody(e.target.value)}
                                    height="150px"
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Resultado</FormLabel>
                                {testResult &&
                                    <JSONTree data={JSON.parse(testResult)} />
                                }
                            </FormControl>
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            colorScheme="blue"
                            onClick={handleTest}
                            isLoading={testLoading}
                            loadingText="Procesando..."
                        >
                            Probar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {loading ? (
                <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='blue.500'
                    size='xl'
                    alignSelf='center'
                />
            ) : (
                <>
                    {processes.map((process) => (
                        <Card key={process._id}>
                            <CardHeader>
                                <Heading size='md'>
                                    📄 {process.name}
                                </Heading>
                            </CardHeader>
                            <CardBody>
                                <Text>Schema: {process.body}</Text>
                            </CardBody>
                            <CardFooter>
                                <Button onClick={() => setProcess(process)} mr={2}>
                                    Modificar
                                </Button>
                                <IconButton
                                    icon={<FaPlay />}
                                    colorScheme="green"
                                    onClick={() => openTestModal(process)}
                                    mr={2}
                                />
                                <Button
                                    colorScheme="red"
                                    onClick={() => handleDelete(process._id)}
                                >
                                    Eliminar
                                </Button>
                            </CardFooter>
                        </Card>
                    ))}
                    <Card>
                        <CardBody>
                            <Button onClick={createProcess}>Crear Proceso</Button>
                        </CardBody>
                    </Card>
                </>
            )}
        </SimpleGrid>
    );
}

export default Procesito;