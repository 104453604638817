import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  IconButton,
  Input,
  Button,
  Flex,
  Text,
  VStack,
  useToast,
  HStack,
} from '@chakra-ui/react';
import { FaRobot, FaTimes, FaRedo } from 'react-icons/fa';
import { io } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';

const ChatWidget = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [conversationId, setConversationId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isConnected, setIsConnected] = useState(false); // Nuevo estado para la conexión
  const messagesEndRef = useRef(null);
  const toast = useToast();

  // Ref para mantener la referencia al socket actual
  const socketRef = useRef(null);

  // Obtener o generar el conversationId al montar el componente
  useEffect(() => {
    let storedConversationId = localStorage.getItem('conversationId');
    if (!storedConversationId) {
      storedConversationId = uuidv4();
      localStorage.setItem('conversationId', storedConversationId);
    }
    setConversationId(storedConversationId);
  }, []);

  // Configurar el socket cada vez que conversationId cambie
  useEffect(() => {
    if (conversationId) {
      // Si hay un socket existente, lo desconectamos
      if (socketRef.current) {
        socketRef.current.disconnect();
      }

      // Crear un nuevo socket y conectarlo
      const newSocket = io('/webchat', {
        auth: { conversationId },
      });

      socketRef.current = newSocket;

      // Escuchar eventos del servidor
      newSocket.on('connect', () => {
        console.log('Conectado al WebSocket');
        setIsConnected(true);
        // Solicitar los mensajes anteriores al conectar
        newSocket.emit('getPreviousMessages', { conversationId });
      });

      newSocket.on('message', (message) => {
        setMessages((prev) => [...prev, message]);
      });

      newSocket.on('previousMessages', (previousMessages) => {
        setMessages(previousMessages);
      });

      newSocket.on('disconnect', () => {
        console.log('Desconectado del WebSocket');
        setIsConnected(false);
        toast({
          title: 'Desconectado del servidor',
          description: 'La conexión con el servidor se ha perdido.',
          status: 'warning',
          duration: 5000,
          isClosable: true,
        });
      });

      // Manejador de errores de conexión
      newSocket.on('connect_error', (err) => {
        console.error('Error de conexión:', err);
        setIsConnected(false);
        toast({
          title: 'Error de conexión',
          description: 'No se pudo conectar al servidor.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });

      // Reconexión exitosa
      newSocket.on('reconnect', () => {
        console.log('Reconectado al WebSocket');
        setIsConnected(true);
        toast({
          title: 'Reconectado al servidor',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        // Solicitar nuevamente los mensajes anteriores
        newSocket.emit('getPreviousMessages', { conversationId });
      });

      // Limpiar al desmontar o cambiar de conversationId
      return () => {
        newSocket.disconnect();
      };
    }
  }, [conversationId]);

  useEffect(() => {
    // Desplazarse al último mensaje al recibir uno nuevo
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const resetConversation = () => {
    const newConversationId = uuidv4();
    localStorage.setItem('conversationId', newConversationId);
    setConversationId(newConversationId);
    setMessages([]);
    toast({
      title: 'Conversación reiniciada',
      status: 'info',
      duration: 3000,
      isClosable: true,
    });
  };

  const sendMessage = () => {
    if (input.trim() && conversationId && socketRef.current && isConnected) {
      socketRef.current.emit('message', { conversationId, text: input });
      setMessages((prev) => [...prev, { text: input, sender: 'User' }]);
      setInput('');
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      sendMessage();
    }
  };

  return (
    <>
      {/* Ícono Flotante */}
      <Box position="fixed" bottom="20px" right="20px" zIndex="1000">
        <IconButton
          icon={isOpen ? <FaTimes /> : <FaRobot />}
          isRound
          size="lg"
          colorScheme="teal"
          onClick={toggleChat}
          aria-label="Abrir Chat"
        />
      </Box>

      {/* Ventana Flotante del Chat */}
      {isOpen && (
        <Box
          position="fixed"
          bottom="80px"
          right="20px"
          width="300px"
          height="400px"
          bg="white"
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          boxShadow="lg"
          zIndex="1000"
        >
          {/* Header del Chat */}
          <Flex
            alignItems="center"
            justifyContent="space-between"
            bg="teal.500"
            color="white"
            px={4}
            py={2}
          >
            <Text fontWeight="bold">Webchat</Text>
            <HStack spacing={2}>
              {!isConnected && <Text fontSize="sm">(Desconectado)</Text>}
              <IconButton
                icon={<FaRedo />}
                size="sm"
                variant="ghost"
                color="white"
                onClick={resetConversation}
                aria-label="Reiniciar conversación"
              />
            </HStack>
          </Flex>

          {/* Cuerpo del Chat */}
          <VStack
            spacing={3}
            p={3}
            height="calc(100% - 100px)" // Ajustar la altura según el header y el input
            overflowY="auto"
          >
            {messages.map((msg, index) => (
              <Box key={index} width="100%">
                {msg.sender === 'User' ? (
                  <Flex justify="flex-end">
                    <Box
                      bg="gray.200"
                      color="black"
                      px={3}
                      py={2}
                      borderRadius="lg"
                      maxWidth="80%"
                      fontSize={12}
                    >
                      {msg.text}
                    </Box>
                  </Flex>
                ) : (
                  <Flex justify="flex-start">
                    <Box
                      bg="teal.500"
                      color="white"
                      px={3}
                      py={2}
                      borderRadius="lg"
                      maxWidth="80%"
                      fontSize={12}
                    >
                      {msg.text}
                    </Box>
                  </Flex>
                )}
              </Box>
            ))}
            <div ref={messagesEndRef} />
          </VStack>

          {/* Input para enviar mensajes */}
          <Flex p={2} borderTopWidth="1px">
            <Input
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyUp={handleKeyPress}
              placeholder="Escribe un mensaje..."
              mr={2}
              isDisabled={!isConnected} // Deshabilitar si no está conectado
            />
            <Button onClick={sendMessage} colorScheme="teal" isDisabled={!isConnected}>
              Enviar
            </Button>
          </Flex>
        </Box>
      )}
    </>
  );
};

export default ChatWidget;