import React from 'react';
import { ChakraProvider, Box, Flex, Button, Image, Heading } from '@chakra-ui/react';
import seguLogo from '../images/segulogo.png';

// Componente de inicio de sesión
function AzureLogin({handleLogin}) {
  return (
    <ChakraProvider>
      <Flex
        height="100vh"
        alignItems="center"
        justifyContent="center"
        bg="#f3f2f1"
      >
        <Box
          width="400px"
          p="8"
          borderRadius="8px"
          boxShadow="lg"
          bg="white"
          textAlign="center"
        >
          {/* Logo de tu aplicación */}
          <Image
            src={seguLogo}  // Reemplaza con la URL de tu logo
            alt="Logo"
            mb="6"
            mx="auto"
          />
          <Heading as="h2" size="md" mb="6">
            Iniciar sesión
          </Heading>

          {/* Botón de inicio de sesión con Microsoft */}
          <Button
            leftIcon={<Image src="https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg" alt="Microsoft Logo" boxSize="20px" />}
            colorScheme="blue"
            variant="outline"
            width="100%"
            onClick={handleLogin}
          >
            Iniciar sesión con Microsoft
          </Button>
        </Box>
      </Flex>
    </ChakraProvider>
  );
};

export default AzureLogin;
