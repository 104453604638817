import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
    auth: {
        clientId: "59573f4e-5b26-42fc-8ffa-7bb4eef3cdd0",
        authority: `https://login.microsoftonline.com/19a1a8ec-9420-43a3-9403-9833a10ce204`,
        redirectUri: window.location.origin,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case 'Error':
                        console.error(message);
                        break;
                    case 'Info':
                        console.info(message);
                        break;
                    case 'Verbose':
                        console.debug(message);
                        break;
                    case 'Warning':
                        console.warn(message);
                        break;
                    default:
                        break;
                }
            },
            piiLoggingEnabled: false,
            logLevel: 'Verbose',
        }
    }
};

export const msalInstance = new PublicClientApplication(msalConfig);
