import React from 'react';
import ReactDOM from 'react-dom';
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from './config/AuthConfig';
import App from './App';
import { ChakraProvider } from '@chakra-ui/react';

ReactDOM.render(
    <MsalProvider instance={msalInstance}>
        <ChakraProvider>
            <App />
        </ChakraProvider>
    </MsalProvider>,
    document.getElementById('root'));